import {BoxGeometry, Mesh, MeshLambertMaterial} from "three";

export function getAxle() {
    const mesh = new Mesh(
        new BoxGeometry(12, 33, 12),
        new MeshLambertMaterial({color: 0x333333})
    );
    mesh.castShadow = true;
    return mesh;
}
