import {
    AmbientLight, DirectionalLight, OrthographicCamera,
    Scene, WebGLRenderer
} from 'three';

import WEBGL from "three/examples/jsm/capabilities/WebGL";
import {getCar} from './Car/Car.ts'
import {getLorry} from "./Car/Lorry";
import {getTree} from "./Environment/Tree";
import {renderTrack, trackRadius, trackWidth, arcCenterX} from "./Environment/Track";

const scene = new Scene();

const ambientLight = new AmbientLight(0xffffff, 0.6);
scene.add(ambientLight);

const dirLight = new DirectionalLight(0xffffff, 0.6);
dirLight.position.set(200, -200, 400);
dirLight.castShadow = true;
dirLight.shadow.mapSize.width = 1024;
dirLight.shadow.mapSize.height = 1024;
dirLight.shadow.camera.far = 4000;
dirLight.shadow.camera.left = -400;
dirLight.shadow.camera.right = 350;
dirLight.shadow.camera.top = 350;
dirLight.shadow.camera.bottom = -300;
scene.add(dirLight);

let innerWidth = 1400; //window.innerWidth;
let innerHeight = 800; // window.innerHeight;
const aspectRatio = innerWidth / innerHeight;
const cameraWith = 960;
const cameraHeight = cameraWith / aspectRatio;

const camera = new OrthographicCamera(
    cameraWith / -2, // LEFT
    cameraWith / 2, // RIGHT
    cameraHeight / 2, // TOP
    cameraHeight / -2, // BOTTOM
    0, // near plane
    1000 // far plane
);
camera.position.set (0, -210, 300);
camera.lookAt(0, 0, 0);

renderTrack(scene, cameraWith, cameraHeight * 2);

let ready;
let playerAngleMoved, score;
let lastTimestamp;
let accelerate = false, decelerate = false;
const speed = 0.0017;
const playerAngleInitial = Math.PI;
const scoreElement = document.getElementById('score');
const playerCar = getCar();
scene.add(playerCar);
let otherVehicles = [];
const renderer = new WebGLRenderer({antialias: true, powerPreference: 'high-performance'});

if ( WEBGL.isWebGLAvailable() ) {

    renderer.setSize(innerWidth, innerHeight);
    renderer.shadowMap.enabled = true;
    reset();

    document.getElementById('app').appendChild(renderer.domElement);

} else {

    const warning = WEBGL.getWebGLErrorMessage();
    document.getElementById( 'app' ).appendChild( warning );

}

function reset() {
    playerAngleMoved = 0;
    movePlayerCar(0);
    score = 0;
    scoreElement.innerText = score;
    lastTimestamp = undefined;

    otherVehicles.forEach((vehicle) => {
        scene.remove(vehicle.mesh);
    });
    otherVehicles = [];

    renderer.render(scene, camera);
    ready = true;
}

function startGame() {
    if (ready) {
        ready = false;
        renderer.setAnimationLoop(animation);
    }
}

function doAccelerate(event) {
    startGame();
    accelerate = true;
    event.preventDefault();
}
function dontAccelerate(event) {
    accelerate = false;
    event.preventDefault();
}

function doDecelerate(event) {
    decelerate = true;
    event.preventDefault();
}
function dontDecelerate(event) {
    decelerate = false;
    event.preventDefault();
}

document.getElementById('accelerate')?.addEventListener('mousedown', doAccelerate);
document.getElementById('accelerate')?.addEventListener('touchstart', doAccelerate);
document.getElementById('accelerate')?.addEventListener('mouseup', dontAccelerate);
document.getElementById('accelerate')?.addEventListener('touchend', dontAccelerate);
document.getElementById('decelerate')?.addEventListener('mousedown', doDecelerate);
document.getElementById('decelerate')?.addEventListener('touchstart', doDecelerate);
document.getElementById('decelerate')?.addEventListener('mouseup', dontDecelerate);
document.getElementById('decelerate')?.addEventListener('touchend', dontDecelerate);

window.addEventListener("keydown", function (event) {
    if (event.key == "ArrowUp") {
        doAccelerate();
        return;
    }
    if (event.key == "ArrowDown") {
        doDecelerate()
        return;
    }
    if (event.key == "R" || event.key == "r") {
        reset();
        return;
    }
});

window.addEventListener("keyup", function (event) {
    if (event.key == "ArrowUp") {
        dontAccelerate();
        return;
    }
    if (event.key == "ArrowDown") {
        dontDecelerate()
        return;
    }
});

function animation(timestamp) {
    if (!lastTimestamp) {
        lastTimestamp = timestamp;
        return;
    }

    const timeDelta = timestamp - lastTimestamp;

    movePlayerCar(timeDelta);

    const laps = Math.floor(Math.abs(playerAngleMoved) / (Math.PI * 2));

    if (laps != score) {
        score = laps;
        scoreElement.innerText = score;
    }

    if (otherVehicles.length < (laps + 1) / 5) {
        addVehicle();
    }

    moveOtherVehicles(timeDelta);

    hitDetection();

    renderer.render(scene, camera);
    lastTimestamp = timestamp;
}

function movePlayerCar(timeDelta) {
    const playerSpeed = getPlayerSpeed();
    playerAngleMoved -= playerSpeed * timeDelta;

    const totalPlayerAngle = playerAngleInitial + playerAngleMoved;
    const radius = trackRadius - trackWidth / 2
    const playerX = Math.cos(totalPlayerAngle) * radius - arcCenterX;
    const playerY = Math.sin(totalPlayerAngle) * radius;

    playerCar.position.x = playerX;
    playerCar.position.y = playerY;
    playerCar.rotation.z = totalPlayerAngle - Math.PI / 2;
}

function getPlayerSpeed() {
    if (accelerate) return speed * 2;
    if (decelerate) return speed / 2;
    return speed;
}

function addVehicle() {
    const vehicleTypes = ["car", "lorry"];

    const type = pickRandom(vehicleTypes);
    const mesh = type == "car" ? getCar() : getLorry();
    scene.add(mesh);

    const clockwise = Math.random() >= 0.5;
    const angle = clockwise ? Math.PI / 2 : -Math.PI / 2;

    const speed = getVehilceSpeed(clockwise);

    otherVehicles.push({ mesh, type, clockwise, angle, speed})
}

function getVehilceSpeed(clockwise) {
    return clockwise ? 1.2 : 0.6;
}

function moveOtherVehicles(timeDelta) {
    otherVehicles.forEach((vehicle) => {
        vehicle.angle = vehicle.angle + speed * timeDelta * vehicle.speed * (vehicle.clockwise ? -1 : +1);

        const radius = trackRadius + trackWidth / (vehicle.clockwise ? -2 : +2);
        const vehicleX = Math.cos(vehicle.angle) * radius + arcCenterX;
        const vehicleY = Math.sin(vehicle.angle) * radius;
        const rotation = vehicle.angle + Math.PI / (vehicle.clockwise ? -2 : +2);

        vehicle.mesh.position.x = vehicleX;
        vehicle.mesh.position.y = vehicleY;
        vehicle.mesh.rotation.z = rotation;
    });
}

function pickRandom(items) {
    return items[Math.floor(Math.random()*items.length)];
}

function getHitZonePosition(center, angle, clockwise, distance) {
    if (undefined === center) debugger;
    const directionsAngle = angle + Math.PI / (clockwise ? -2 : +2);
    return {
        x: center.x + Math.cos(directionsAngle) * distance,
        y: center.y + Math.sin(directionsAngle) * distance,
    };
}

function hitDetection() {
    const playerHitZone1 = getHitZonePosition(
        playerCar.position,
        playerAngleInitial + playerAngleMoved,
        true,
        15
    );

    const playerHitZone2 = getHitZonePosition(
        playerCar.position,
        playerAngleInitial + playerAngleMoved,
        true,
        -15
    );

    const hit = otherVehicles.some((vehicle) => {
        if (vehicle.type == "car") {
            const vehicleHitZone1 = getHitZonePosition(
                vehicle.mesh.position,
                vehicle.angle,
                vehicle.clockwise,
                15
            );

            const vehicleHitZone2 = getHitZonePosition(
                vehicle.mesh.position,
                vehicle.angle,
                vehicle.clockwise,
                -15
            );

            if (getDistance(playerHitZone1, vehicleHitZone1) < 40) return true;
            if (getDistance(playerHitZone1, vehicleHitZone2) < 40) return true;
            if (getDistance(playerHitZone2, vehicleHitZone1) < 40) return true;
        } else {
            const vehicleHitZone1 = getHitZonePosition(
                vehicle.mesh.position,
                vehicle.angle,
                vehicle.clockwise,
                35
            );

            const vehicleHitZone2 = getHitZonePosition(
                vehicle.mesh.position,
                vehicle.angle,
                vehicle.clockwise,
                0
            );

            const vehicleHitZone3 = getHitZonePosition(
                vehicle.mesh.position,
                vehicle.angle,
                vehicle.clockwise,
                -35
            );

            if (getDistance(playerHitZone1, vehicleHitZone1) < 40) return true;
            if (getDistance(playerHitZone1, vehicleHitZone2) < 40) return true;
            if (getDistance(playerHitZone1, vehicleHitZone3) < 40) return true;
            if (getDistance(playerHitZone2, vehicleHitZone1) < 40) return true;
        }
    });

    if (hit) renderer.setAnimationLoop(null);


}

function getDistance(coord1, coord2) {
    return Math.sqrt(
        (coord2.x - coord1.x) ** 2 + (coord2.y - coord1.y) ** 2
    );
}
