import {BoxGeometry, Group, Mesh, MeshLambertMaterial, SphereGeometry} from "three";

export function getTree() {

    const tree = new Group();

    const stemheight = 40;
    const crownradius = 30;

    const main = new Mesh(
        new BoxGeometry(15, 15, 40),
        new MeshLambertMaterial({ color: 0x6F4E37 })
    );
    main.position.z = stemheight / 2;
    main.position.x = 0;
    main.castShadow = true;
    tree.add(main);

    const crown = new Mesh(
        new SphereGeometry(crownradius),
        new MeshLambertMaterial({ color: 0x228B22 })
    );
    crown.position.z = stemheight + crownradius;
    crown.position.x = 0;
    crown.castShadow = true;
    tree.add(crown);

    return tree;
}
