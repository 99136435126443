import {
    BoxGeometry, CanvasTexture,
    Group, Mesh, MeshLambertMaterial, Vector2
} from 'three';
import {getAxle} from "./Axle";

export function getCar() {

    const car = new Group();

    const backWheel = getAxle();
    backWheel.position.z = 6;
    backWheel.position.x = -18;
    car.add(backWheel);

    const frontWheel = getAxle();
    frontWheel.position.z = 6;
    frontWheel.position.x = 18;
    car.add(frontWheel);

    const main = new Mesh(
        new BoxGeometry(60, 30, 15),
        new MeshLambertMaterial({ color: 0xa52523 })
    );
    main.position.z = 12;
    main.castShadow = true;
    car.add(main);

    const carFrontTexture = getCarFrontTexture();
    carFrontTexture.center = new Vector2(0.5, 0.5);
    carFrontTexture.rotation = Math.PI / 2;

    const carBackTexture = getCarFrontTexture();
    carBackTexture.center = new Vector2(0.5, 0.5);
    carBackTexture.rotation = Math.PI / -2;

    const carRightTexture = getCarSideTexture();
    const carLeftTexture = getCarSideTexture();
    carLeftTexture.flipY = false;

    const cabin = new Mesh(
        new BoxGeometry(33, 24, 12),
        [
            new MeshLambertMaterial({ map: carFrontTexture }),
            new MeshLambertMaterial({ map: carBackTexture }),
            new MeshLambertMaterial({ map: carLeftTexture }),
            new MeshLambertMaterial({ map: carRightTexture }),
            new MeshLambertMaterial({ color: 0xffffff }),
            new MeshLambertMaterial({ color: 0xffffff })
        ]
    );
    cabin.position.z = 25.5;
    cabin.position.x = -6;
    cabin.castShadow = true;
    car.add(cabin);

    return car;
}

function getCarFrontTexture() {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 32;

    const context = canvas.getContext('2d');

    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, 64, 32);

    context.fillStyle = '#666666';
    context.fillRect(8, 8, 48, 24);

    return new CanvasTexture(canvas);

}
function getCarSideTexture() {
    const canvas = document.createElement('canvas');
    canvas.width = 128;
    canvas.height = 32;

    const context = canvas.getContext('2d');

    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, 128, 32);

    context.fillStyle = '#666666';
    context.fillRect(10, 8, 38, 24);
    context.fillRect(58, 8, 60, 24);

    return new CanvasTexture(canvas);

}
