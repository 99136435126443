import {CanvasTexture, ExtrudeGeometry, Mesh, MeshLambertMaterial, PlaneGeometry, Shape} from "three";
import {getTree} from "./Tree";

export const trackRadius = 225;
export const trackWidth = 45;

export const innerTrackRadius = trackRadius - trackWidth;
export const outerTrackRadius = trackRadius + trackWidth;

const arcAngle1 = (1/3) * Math.PI;

const deltaY = Math.sin(arcAngle1) * innerTrackRadius;
const arcAngle2 = Math.asin(deltaY / outerTrackRadius);

export const arcCenterX = (
    Math.cos(arcAngle1) * innerTrackRadius +
    Math.cos(arcAngle2) * outerTrackRadius
) / 2;

const arcAngle3 = Math.acos(arcCenterX / innerTrackRadius);
const arcAngle4 = Math.acos(arcCenterX / outerTrackRadius);

export function renderTrack(scene, mapWidth, mapHeight) {

    const lineMarkingsTexture = getLineMarkings(mapWidth, mapHeight);

    const planeGeometry = new PlaneGeometry(mapWidth, mapHeight);
    const planeMaterial = new MeshLambertMaterial({
        map: lineMarkingsTexture
    });
    const plane = new Mesh(planeGeometry, planeMaterial);
    plane.receiveShadow = true;
    scene.add(plane);

    const islandLeft = getLeftIsland();
    const islandMiddle = getMiddleIsland();
    const islandRight = getRightIsland();
    const outerField = getOuterField(mapWidth, mapHeight);

    const fieldGeometry = new ExtrudeGeometry(
        [islandLeft, islandMiddle, islandRight, outerField],
        { depth: 6, bevelEnabled: false}
    );

    const fieldMesh = new Mesh(fieldGeometry, [
        new MeshLambertMaterial({ color: 0x67c240}),
        new MeshLambertMaterial({ color: 0x23311c}),
    ]);
    fieldMesh.receiveShadow = true;
    scene.add(fieldMesh);

    const treeSpecs = [
        {x: 0, y: -40, zRot: Math.PI / 7},
        {x: -200, y: -100, zRot: Math.PI / 3},
        {x: 200, y: 100, zRot: Math.PI / 4},
        {x: 400, y: 200, zRot: Math.PI / 4},
        {x: -350, y: 200, zRot: Math.PI / 4},
        {x: -370, y: -220, zRot: Math.PI / 4},
    ]
    treeSpecs.forEach((spec) => {
        const tree = getTree();
        tree.position.x = spec.x;
        tree.position.y = spec.y;
        tree.rotation.z = spec.zRot;
        scene.add(tree);
    })
}

function getLineMarkings(mapWidth, mapHeight) {
    const canvas = document.createElement('canvas');
    canvas.width = mapWidth;
    canvas.height = mapHeight;

    const context = canvas.getContext('2d');

    context.fillStyle = '#546E90';
    context.fillRect(0, 0, mapWidth, mapHeight);
    context.lineWidth = 2;
    context.strokeStyle = '#E0FFFF';
    context.setLineDash([10, 14]);

    context.beginPath();
    context.arc(
        mapWidth / 2 - arcCenterX,
        mapHeight / 2,
        trackRadius,
        0,
        Math.PI * 2
    );
    context.stroke();

    context.beginPath();
    context.arc(
        mapWidth / 2 + arcCenterX,
        mapHeight / 2,
        trackRadius,
        0,
        Math.PI * 2
    );
    context.stroke();

    return new CanvasTexture(canvas);
}

function getLeftIsland() {
    const islandLeft = new Shape();

    islandLeft.absarc(
        -arcCenterX,
        0,
        innerTrackRadius,
        arcAngle1,
        -arcAngle1,
        false
    );

    islandLeft.absarc(
        arcCenterX,
        0,
        outerTrackRadius,
        Math.PI + arcAngle2,
        Math.PI - arcAngle2,
        true
    );

    return islandLeft;
}

function getRightIsland() {
    const islandRight = new Shape();

    islandRight.absarc(
        arcCenterX,
        0,
        innerTrackRadius,
        Math.PI - arcAngle1,
        Math.PI + arcAngle1,
        true
    );

    islandRight.absarc(
        -arcCenterX,
        0,
        outerTrackRadius,
        -arcAngle2,
        arcAngle2,
        false
    );

    return islandRight;
}


function getMiddleIsland() {
    const islandMiddle = new Shape();

    islandMiddle.absarc(
        -arcCenterX,
        0,
        innerTrackRadius,
        arcAngle3,
        -arcAngle3,
        true
    );

    islandMiddle.absarc(
        arcCenterX,
        0,
        innerTrackRadius,
        Math.PI + arcAngle3,
        Math.PI - arcAngle3,
        true
    );

    return islandMiddle;
}

function getOuterField(mapWidth, mapHeight) {
    const field = new Shape();

    field.moveTo(-mapWidth / 2, -mapHeight / 2);
    field.lineTo(0, -mapHeight / 2);

    field.absarc(
        -arcCenterX,
        0,
        outerTrackRadius,
        -arcAngle4,
        arcAngle4,
        true
    );

    field.absarc(
        arcCenterX,
        0,
        outerTrackRadius,
        Math.PI - arcAngle4,
        Math.PI + arcAngle4,
        true
    );

    field.lineTo(0, -mapHeight / 2);
    field.lineTo(mapWidth / 2, -mapHeight / 2);
    field.lineTo(mapWidth / 2, mapHeight / 2);
    field.lineTo(-mapWidth / 2, mapHeight / 2);

    return field;

}



