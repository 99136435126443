import {BoxGeometry, Group, Mesh, MeshLambertMaterial} from "three";
import {getAxle} from "./Axle";

export function getLorry() {

    const lorry = new Group();

    const backWheel = getAxle();
    backWheel.position.z = 6;
    backWheel.position.x = -18;
    lorry.add(backWheel);

    const middleWheel = getAxle();
    middleWheel.position.z = 6;
    middleWheel.position.x = 18;
    lorry.add(middleWheel);

    const frontWheel = getAxle();
    frontWheel.position.z = 6;
    frontWheel.position.x = 48;
    lorry.add(frontWheel);

    const main = new Mesh(
        new BoxGeometry(45, 25, 10),
        new MeshLambertMaterial({ color: 0xffffff })
    );
    main.position.z = 15;
    main.position.x = 35;
    main.castShadow = true;
    lorry.add(main);

    const cabin = new Mesh(
        new BoxGeometry(20, 30, 32),
        new MeshLambertMaterial({ color: 0xffff55 })
    );
    cabin.position.z = 20;
    cabin.position.x = 50;
    cabin.castShadow = true;
    lorry.add(cabin);

    const trailer = new Mesh(
        new BoxGeometry(75, 32, 45),
        new MeshLambertMaterial({ color: 0xccddff })
    );
    trailer.position.z = 35;
    trailer.position.x = -3;
    trailer.castShadow = true;
    lorry.add(trailer);

    return lorry;

}
